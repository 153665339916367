import uuid from 'uuid';
import { clone, mergeRight, remove, concat } from 'ramda';
import {
  AdminProcedureState,
  OneProcedure,
  PaginatedProcedures,
  UpdateProcedureOptions,
  UpdateTaskRuleStepPayload
} from './types';
import { errors, many, progress } from 'admin/state/base_store';
import Vue from 'vue';

// Objects are passed by reference in JS, so we don't need to return anything here
const removeFormInfo = (task) => {
  task.form = null;
  task.repetitionValue = null;
  task.repetitionUnits = null;
  task.gracePeriodValue = null;
  task.gracePeriodUnits = null;
  task.requiredElements = [];
};

const extractTask = (taskForm) => {
  return {
    task_id: taskForm.taskId || taskForm.task_id,
    step_id: taskForm.stepId || taskForm.step_id,
    taskId: taskForm.taskId || taskForm.task_id,
    stepId: taskForm.stepId || taskForm.step_id,
    title: taskForm.title,
    priority: taskForm.priority,
    owner: taskForm.owner,
    machine: taskForm.machine,
    requiredElements: taskForm.requiredElements,
    procedureFormId: taskForm.procedureFormId,
    procedureTaskRules: taskForm.procedureTaskRules,
    repetitionValue: taskForm.repetitionValue,
    repetitionUnits: taskForm.repetitionUnits,
    gracePeriodValue: taskForm.gracePeriodValue,
    gracePeriodUnits: taskForm.gracePeriodUnits
  };
};

const extractProcedureForm = (taskForm) => {
  return {
    procedureFormId: taskForm.procedureFormId,
    formId: taskForm.form.id,
    form: taskForm.form,
    repetitionValue: taskForm.repetitionValue,
    repetitionUnits: taskForm.repetitionUnits,
    gracePeriodValue: taskForm.gracePeriodValue,
    gracePeriodUnits: taskForm.gracePeriodUnits
  };
};

const addOrEditProcedureForm = (state, taskForm, task) => {
  const procedureForm = extractProcedureForm(taskForm);
  if (taskForm.procedureFormId) {
    state.procedure.procedureForms.forEach((pf, i) => {
      if (pf.procedureFormId === taskForm.procedureFormId) {
        state.procedure.procedureForms[i] = procedureForm;
      }
    });
  } else {
    procedureForm.procedureFormId = uuid.v4();
    task.procedureFormId = procedureForm.procedureFormId;
    state.procedure.procedureForms.push(procedureForm);
  }
};

export default {
  one(state: AdminProcedureState, data: OneProcedure) {
    state.errors = {};
    state.procedure = Object.assign(clone(state.procedure), data.procedure);
    state.savedProcedure = clone(data.procedure);
    state.hasUpdated = false;
    return state;
  },
  many: many<AdminProcedureState, PaginatedProcedures>('procedures'),
  progress,
  errors,
  setStepIndex(state, i) {
    state.stepIndex = i;
    state.hasUpdated = true;
  },
  addStep(state, step) {
    const stepIndex = state.procedure.steps.length;
    step.stepId = uuid.v4();
    step.tasks = step.tasks || [];
    step.sort_order = stepIndex;
    state.procedure.steps.push(step);
    state.hasUpdated = true;
    return state;
  },
  editStep(state, step) {
    state.procedure.steps[state.stepIndex].title = step.title;
    state.hasUpdated = true;
    return state;
  },
  clearStep(state) {
    state.step = {
      title: '',
      tasks: []
    };
    return state;
  },
  addTask(state, taskForm) {
    const task = extractTask(taskForm);
    if (taskForm.form) {
      addOrEditProcedureForm(state, taskForm, task);
    }

    state.procedure.steps[taskForm.step].tasks.push(task);
    state.procedure = clone(state.procedure);
    state.hasUpdated = true;
  },

  updateProcedureTask(state, { stepIndex, taskIndex, taskForm }: UpdateProcedureOptions) {
    const task = extractTask(taskForm);
    if (taskForm.form) {
      addOrEditProcedureForm(state, taskForm, task);
    }

    state.procedure.steps[stepIndex].tasks[taskIndex] = task;

    // Update the cohortCounts, remove unused procedureForms
    const procedureFormIds = state.procedure.procedureForms.map((pf) => pf.procedureFormId);
    const cohortCounts = {};
    procedureFormIds.forEach((id) => cohortCounts[id] = 0);

    state.procedure.steps.forEach((s) => {
      s.tasks.forEach((t) => {
        if (t.procedureFormId) { cohortCounts[t.procedureFormId] += 1; }
      });
    });

    // That was so much fun, let's do it again!
    state.procedure.steps.forEach((s) => {
      s.tasks.forEach((t) => {
        if (t.procedureFormId) { t.cohortCount = cohortCounts[t.procedureFormId]; }
      });
    });

    state.procedure.procedureForms = state.procedure.procedureForms.filter((pf) => {
      return cohortCounts[pf.procedureFormId] > 0;
    });

    state.procedure = clone(state.procedure);
    state.hasUpdated = true;
  },

  removeTask(
    state: AdminProcedureState,
    { stepIndex, taskIndex }: {stepIndex: number; taskIndex: number; }
  ): void {
    const steps = state.procedure.steps;
    const tasks = steps[stepIndex].tasks;
    const removedTask = steps[stepIndex].tasks[taskIndex];

    steps[stepIndex].tasks = remove(taskIndex, 1, tasks);
    // Remove any task rules that referenced the task as a target
    steps.forEach(step => {
      step.tasks.forEach(task => {
        const rules = task.procedureTaskRules;
        if (rules) task.procedureTaskRules = rules.filter(rule => {
          return !rule.targetTasks.some(target => target.taskId === removedTask.task_id)
        });
      });
    });

    state.procedure.steps = steps;
    state.hasUpdated = true;
  },

  updateTask(state, props) {
    state.task = mergeRight(state.task, props);
    state.hasUpdated = true;
  },

  updateTaskModel(state, { key, value }) {
    const newTask = { ...state.task };

    switch (key) {
    case 'controlType':
      newTask.controlType = value;
      removeFormInfo(newTask);
      if (value === 'new-form') {
        newTask.procedureFormId = newTask.originalProcedureFormId;
      }
      break;
    case 'form':
      newTask.form = value;
      newTask.requiredElements = [];
      if (!value) {
        newTask.procedureFormId = null;
        removeFormInfo(newTask);
      }
      break;
    case 'procedureForm':
      newTask.procedureFormId = value.id;
      newTask.form = value.procedureForm.form;
      newTask.repetitionValue = value.procedureForm.repetitionValue;
      newTask.repetitionUnits = value.procedureForm.repetitionUnits;
      newTask.gracePeriodValue = value.procedureForm.gracePeriodValue;
      newTask.gracePeriodUnits = value.procedureForm.gracePeriodUnits;
      newTask.requiredElements = [];
      break;
    default:
      newTask[key] = value;
    }

    state.task = newTask;
  },

  setIndex(state, i) {
    state.task.step = i;
  },
  clearTask(state) {
    state.task = {
      // stuff for managing the form
      controlType: 'new-form',
      originalProcedureFormId: null,
      // task stuff
      step: -1,
      title: '',
      procedure_task_id: null,
      version: 0,
      procedure_step_id: null,
      requiredElements: [],
      cohortCount: 0,
      owner: null,
      machine: null,
      // procedure form stuff
      procedureFormId: null,
      form: null,
      repetitionValue: null,
      repetitionUnits: null,
      gracePeriodValue: null,
      gracePeriodUnits: null,
      assignee: {},
      priority: '',
      dueDate: null
    };
  },
  removeStep(
    state: AdminProcedureState,
    { stepIndex }: {stepIndex: number; }
  ): void {
    const steps = state.procedure.steps;

    state.procedure.steps = remove(stepIndex, 1, steps);
    state.hasUpdated = true;
  },
  setSummaryForm(state: AdminProcedureState, form) {
    state.procedure = clone(state.procedure);
    if (form) {
      state.procedure.summaryForm = form;
      Vue.set(state.procedure, 'form', form);
    } else {
      state.procedure.summaryForm = { formId: null, formVersion: null };
      Vue.set(state.procedure, 'form', null);
      Vue.set(state.procedure, 'procedureSummaryFormTaskRules', []);
    }
  },
  clearSummary(state) {
    state.errors = {};
    state.progress = '';
  },
  toggleSummaryModal(state, toggled) {
    state.summaryModalToggled = toggled;
  },
  clearProcedure(state) {
    state.procedure = {
      title: '',
      status: 'published',
      steps: [],
      summaryForm: {},
      procedureForms: [],
      notificationActionsRequired: false,
      showNotRequired: false
    };
  },
  updateSettings(state, { key, value }) {
    if (key === 'showNotRequired' && value === '') { return; }
    state.procedure[key] = value;
    state.hasUpdated = true;
  },
  clearSettings(_state) { /* noop */ },
  setHasUpdated(state, hasUpdated) {
    state.hasUpdated = hasUpdated;
  },
  setTaskRule(state, { task, taskRule }) {
    const step = state.procedure.steps.find((s) => s.stepId === task.step_id);
    if (step) {
      const taskToUpdate = step.tasks.find((t) => t.task_id === task.task_id);
      if (taskToUpdate) {
        taskRule.procedureTaskId = task.id;
        taskToUpdate.procedureTaskRules.push(taskRule);
        state.procedure = clone(state.procedure);
        state.hasUpdated = true;
      }
    }
  },
  setSummaryFormTaskRule(state, { taskRule }) {
    state.procedure = clone(state.procedure);
    Vue.set(state.procedure, 'procedureSummaryFormTaskRules', concat(state.procedure.procedureSummaryFormTaskRules || [], [taskRule]));
    state.hasUpdated = true;
  },

  removeTaskRule(state, { procedureStepId, procedureTaskId, index }) {
    const step = state.procedure.steps.find((s) => s.id === procedureStepId);
    if (step) {
      const taskToUpdate = step.tasks.find((t) => t.id === procedureTaskId);
      if (taskToUpdate) {
        taskToUpdate.procedureTaskRules.splice(index, 1);
        state.procedure = clone(state.procedure);
        state.hasUpdated = true;
      }
    }
  },

  removeSummaryFormTaskRule(state, { index }) {
    state.procedure.procedureSummaryFormTaskRules.splice(index, 1);
    state.procedure = clone(state.procedure);
    state.hasUpdated = true;
  },

  UPDATE_TASK_RULE_STEP(state: AdminProcedureState, { taskId, stepId, stepTitle }: UpdateTaskRuleStepPayload): void {
    const steps = state.procedure.steps;

    steps.forEach(step => {
      step.tasks.forEach(task => {
        const rules = task.procedureTaskRules;
        if (rules) {
          rules.forEach(rule => {
            rule.targetTasks.forEach(tt => {
              if (tt.taskId === taskId) {
                tt.stepId = stepId;
                tt.stepTitle = stepTitle;
              }
            });
          });
        }
      });
    });

    state.procedure.steps = steps;
    state.hasUpdated = true;
  }
};
